.details-container {
  margin-bottom: 60px;
  & > * {
    margin-top: 10px !important;
  }
}
.details-title {
  word-break: break-all;
}

.pd-section {
  margin-bottom: 40px !important;
}

.part-details-action-bar .MuiButton-outlined {
  margin: 0 3px;
}
