.cc-expander {
  .cc-header {
    padding: 0;

    .cc-expand-icon {
      padding-right: 5px;
    }

    .MuiTableCell-root {
      border: 0;
    }

    .MuiExpansionPanelSummary-expandIcon {
      display: none;
    }
  }

  .cc-details {
    padding: 0;

    .cc-details-element {
      width: 100%;

      .cc-configs-empty {
        padding: 20px;
      }

      .cc-add-config-button {
        margin: 10px;
      }
    }
  }
}
