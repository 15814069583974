  .krtz-list .krtz-item {
    border-top: 1px solid #ccc;
  }
  
  .krtz-list .krtz-item:first-child {
    border: none;
  }

  .krtz-list .krtz-item:hover { 
    background-color: #f4f5f7;
  }

  .krtz-list .krtz-item.selected {
    background-color: #deebff;
   }
  
  div[class^="memo-root"] {
    z-index: 10000 !important;
  }