.cm-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.3);

  .cm-modal {
    position: fixed;
    background: rgb(255, 255, 255);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    width: 50%;
    height: 50%;
    overflow: hidden;
    padding: 20px;

    display: flex;
    flex-direction: column;

    .cm-header {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      min-height: 60px;

      .cm-title {
        flex: 10;
        padding: 0 30px;
        text-align: center;
        font-weight: 500;
        margin: 15px 10px 20px;
      }

      .cm-close {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 3px;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        cursor: pointer;
        margin: 10px;

        &:hover {
          background-color: #88898a;
          color: #fff;
        }
      }
    }

    .cm-content {
      text-align: center;
      padding: 0 25px;
      flex-grow: 1;
    }

    .cm-actions {
      height: 45px;
      margin: 15px 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      & > * {
        height: auto;
        margin: 0 5px;
      }
    }

    .cm-scrollable {
      overflow-x: scroll;
      overflow-y: scroll;
    }

    .cm-scrollableX {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .cm-scrollableY {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}
