.upload-modal {
  width: 85vw;
  max-width: 1000px;
  height: 80vh;
  max-height: 1000px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
  padding: 20px;
}

.close-upload-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}

.upload-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}