.configuration-table-empty-box {
  width: 100%;
}

.configuration-section-title {
  margin: 40px 0 20px;
}

.configuration-add-button {
  margin: 20px 0;
}

.configuration-table {
  margin: 20px 0 40px;
}
