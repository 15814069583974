.edit-icon {
  margin-left: 15px;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  display: flex;

  .inline-action-buttons {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    z-index: 5000;

    .MuiButton-root {
      min-width: 30px !important;
      min-height: 30px !important;
      background-color: white;
    }

    & > * {
      border: 1px solid #969696;
      margin: 3px 3px 0;
      padding: 3px;
    }
  }
}
