.list-modal {
  display: block;
  width: 550px !important;
  margin-top: -350px !important;
  margin-left: -20px;
  @media screen and (max-height: 899px) {
    margin-top: -350px !important;
  }
}

.list-modal-item {
  margin: 10px auto !important;
  max-width: 90%;

  .list-modal.hide {
    opacity: 0;
  }

  .list-description {
    text-align: left;

    & > * {
      height: 25px;
      margin: 0;
      display: flex;
      align-items: center;
      align-content: center;
    }
  }

  .list-modal-indicator {
    height: 300px;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .list-modal-indicator__label {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
  }
}
