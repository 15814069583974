.part-list-card {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 280px;
  margin-top: 30px;
  border-radius: 10px !important;
  box-shadow: 3px 3px 10px 0px #a1a1a144, 6px 6px 0px 0px #fff,
    9px 9px 10px 0px #a1a1a144, 12px 12px 0px 0px #fff,
    15px 15px 10px 0px #a1a1a144 !important;

  .content {
    flex-grow: 1;
    position: relative;
    width: 100%;

    .part-card-title {
      text-decoration: none;
      color: #000;

      .title {
        display: flex;
        padding: 1rem 1rem;

        span {
          flex-grow: 1;
          font-size: 1.1rem;
          color: #232323;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      .images {
        min-height: 250px;
        display: flex;
        padding: 10px 0;
        margin-bottom: 56px;

        .single-image {
          flex-grow: 1;
          padding: 8px;

          .image {
            width: 100%;
            height: 250px;
            object-fit: contain;
          }
        }
      }

      & > * {
        margin: 0;
      }

      & > h3 {
        margin: 8px 0 0;
        word-break: break-all;
      }

      &:hover {
        color: #006572;
      }
    }
  }

  .part-list-card-navfooter {
    padding: 0 0.75rem 0 1rem;
    width: 100%;
    height: 50px;
    bottom: 6px;
    font-size: 0.75rem;

    .list-actions {
      .MuiIconButton-root {
        padding: 8px;

        &:hover {
          background: #00acc122;
          color: #333;
        }

        .MuiSvgIcon-root {
          font-size: 1.33rem;
        }
      }
    }
  }
}
