.hared-parts-table {
  margin-bottom: 40px;
}

.shr-tbl-name {
  width: 300px;
}

.shr-tbl-create-on {
  width: 240px;
}

.shr-tbl-valid-until {
  width: 200px;
}

.shr-tbl-link-status {
  width: 120px;
}

.shr-tbl-link {
  width: 300px;
}

.shared-list-name-link {
  color: #127ed6;
}
