.fm-custom-inp {
	width: 100%;
	display: flex;
	align-items: center;
  label {
    top: 0;
	}
	
	&.side-lbl{
		width: 70%;
		div:first-child {
			margin-right: 15px;
		}

		.side-lbl-value {
			margin-top: 30px;
		}
	}
}

