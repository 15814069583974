.part-thumbnail-container {
  position: relative;

  .part-thumbnail-image {
    width: 100%;
  }

  &:hover {
    & > .part-thumbnail-button {
      display: initial;
    }
  }

  .part-thumbnail-button {
    display: none;
    white-space: normal;
    width: 70%;
    position: absolute !important;
    top: 45%;
    left: 15%;
  }
}
