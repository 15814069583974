.config-substep {
  border: 1px solid rgba(161, 161, 161, 0.267);
  border-right: 0;
  border-radius: 1px 0 0 1px;
  box-shadow: none;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #fff;
    cursor: pointer;
  }

  &.active {
    color: #00acc1;
    background: #fff;
    border: 1px solid rgba(161, 161, 161, 0.267);
    border-bottom: 3px solid #00acc1;
    border-right: 0;
  }

  .config-substep-lbl-btn {
    cursor: pointer;
    text-align: left;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 12px;
    height: 30px;
    display: block;
    overflow: hidden;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .config-substep-lbl-btn.batch-sizes-step {
    white-space: normal;
    word-break: break-all;
    overflow: none;
  }

  .config-substep-lbl-btn.post-process-step {
    width: 100%;
  }

  .remove-substep-btn {
    background: transparent;
    width: 30px;
    height: 24px;
    padding: 0px;
    border: 0;
    cursor: pointer;
    color: #ccc;

    &:hover {
      color: #00acc1;
    }
  }
}
