.part-card {
  position: relative;
  display: flex;
  align-items: stretch;
  flex: 1;
  word-wrap: break-word;
  background: #fff;
  border: 1px solid #eee !important;
  box-shadow: 6px 6px 6px 2px #a1a1a155;
  border-radius: 10px !important;
  max-width: 280px !important;

  &:hover {
    border: 1px solid #00acc1 !important;
  }

  .card-image {
    margin-bottom: 20px;

    img {
      border-radius: 10px;
    }
  }

  .card-body {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #a1a1a155;
    background: #fafafabb;
    width: 100%;
    padding: 0.25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    border-radius: 0 0 10px 10px;

    .content {
      padding: 0 1rem;

      .part-card-title {
        text-decoration: none;
        color: #222;
        display: flex;

        h5 {
          flex-grow: 1;
          font-size: 1.1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        &:hover {
          color: #006572;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      grid-template-columns: 4fr 2fr;

      & > * {
        display: flex;
        align-items: center;
      }

      .footer-info {
        flex-grow: 4;
        padding: 0 0 0 1rem;
        display: flex;
        max-width: 66%;

        p {
          flex-grow: 1;
          margin: 0;
          font-size: 0.75rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      .footer-buttons {
        flex-grow: 2;
        padding: 0 0.25rem;
        justify-content: flex-end;

        .MuiIconButton-root {
          padding: 8px;

          &:hover {
            background: #00acc122;
            color: #333;
          }

          .MuiSvgIcon-root {
            font-size: 1.33rem;
          }
        }
      }
    }
  }
}

.part-card .nav-btn {
  padding: 12px 5px 12px 12px !important;
}

.thumbnail {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
