.configurator-modal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.configurator-modal {
  width: 98vw;
  height: 98vh;
}

.close-configurator-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}

.configurator-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.configurator-root-box {
  display: grid;
  grid-template-columns: 18px 1fr;
  column-gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #43CCAD;
  margin-right: 1px;
  margin-bottom: 1px;

  & > :not(:first-child):not(:nth-child(2)):not(:empty):not(.no-padding-top) {
    padding-top: 8px;
  }
}
