.part-library-config-details-modal {
  width: 85vw;
  max-width: 1000px;
  height: 80vh;
  max-height: 800px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
}

.part-library-config-details-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
}

.part-library-config-details-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 10px 24px !important;
  width: calc(100%) !important;
  height: calc(100%) !important;
  max-height: calc(100%) !important;
  overflow: auto;
}

.close-part-library-config-details-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}

.thumbnail-container {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 8px;
  }
  
  &:hover {
    & > .thumbnail-button {
      display: initial;
    }
  }
  
  .thumbnail-button {
    display: none;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}