.layout {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #00acc1 !important;
  }
}
