.filter-button {
  width: 45px;
  height: 45px;
  background: #32bee1 !important;
}
.filter-wrapper {
  padding: 20px;

  .filter-input {
    min-width: 350px;
    padding: 0 15px 10px;
  }

  .filter-controls {
    display: flex;
    flex-direction: row;
    padding: 0 15px;

    & > * {
      flex: 1;

      .filter-icon {
        margin-left: 7px;
      }
    }
  }
}
.filter-toggle-icon {
  color: #fff;
}
