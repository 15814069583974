.cc-header-thumb {
  width: 100%;
  padding: 15px;
}
.cc-header-info {
  display: block;
  padding: 2px;
  width: 50%;
}

.cc-remove-part {
  margin: 5px !important;
}
