.content {
  display: flex;
  flex-direction: column;
  width: 78%;

  .config-content-top {
    display: flex;
    border-bottom: 1px solid #a1a1a144;

    .stepper {
      flex-grow: 15;
      padding: 20px;
    }

    .close-button {
      border-left: 1px solid #a1a1a144;
    }
    .close-button:hover {
      background-color: #f44336;
      color: #fff;
    }

    .help-button {
      border-left: 1px solid #a1a1a144;
    }
    .help-button:hover {
      background-color: #00acc1;
      color: #fff;
    }
  }

  .config-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
  }

  .config-controls {
    height: 60px;
    border-top: 1px solid #a1a1a144;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    * {
      margin: 0 5px;
    }

    .config-controls-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #00acc1;
      width: 365px;
      text-align: left;
    }
  }
}

.swal2-container {
  z-index: 100000 !important;
}
