.list-actions-button {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 300 !important;
}

.list-actions-menu-item {
  font-weight: 300 !important;
  font-size: 14px !important;
}

.list-actions-menu-item.disabled {
  color: #aaa !important;
}
