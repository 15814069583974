.collapse-row {
  .row-text-cell {
    width: 180px;

    .MuiTypography-body2 {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .collapse-table-id-cell {
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }
}
