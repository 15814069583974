.config-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  margin: 10px 0 0;

  & > button {
    margin-top: 5px;
  }

  .config-title {
    text-align: left;
  }

  .add-substep {
    display: none;
    background: #e1e1e1;
    border-right: 0;
    border-radius: 1px 0 0 1px;
    box-shadow: none;
    padding: 10px 10px;

    &:active {
      border: 1px solid #00acc1;
    }
  }

  .show-add {
    display: block;
  }
}
