.sidebar {
  background: #f8f8f8;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: scroll;
  width: 22%;

  .config-name-wrap {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(161, 161, 161, 0.267);

    .MuiInput-formControl {
      padding: 0 10px;
      font-size: 13px;
    }

    button {
      background-color: #ffffff;
    }
  }

  .config-steps {
    flex: 8;
    overflow-y: auto;
    display: block;

    .config-step:last-child {
      margin-bottom: 20px;
    }

    .part-thumb-sidebar {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 90%;
      margin-left: 6%;
    }
  }

  .part-name {
    padding-top: 8px;
    padding-left: 20px;
    text-align: left;
    height: 60px;
    width: 100%;
    background: #fff;
    border-top: 1px solid rgba(161, 161, 161, 0.267);
    border-right: 1px solid rgba(161, 161, 161, 0.267);

    & > div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .part-name-lbl {
      font-size: 13px;
    }
    .part-name-val {
      font-size: 14px;
      font-weight: bold;
      color: #00acc1;
    }
  }
}
