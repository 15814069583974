.share-details-modal {
  top: 250px !important;
}
.secure-link-modal-content {
  text-align: left;
  padding: 0 10px;
}

.secure-link-modal-content h4 {
  color: #000;
  font-weight: 400;
}

.MuiPopover-root {
  z-index: 15600 !important;
}

#accessibility-label {
  width: 300px;
}

.secure-link-modal-content .inp-error {
  color: #f44336;
}
