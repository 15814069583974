.share-part-list-modal {
  width: 85vw;
  max-width: 500px;
  height: 65vh;
  max-height: 650px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
}

.share-part-list-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
}

.share-part-list-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 20px !important;
  width: calc(100%) !important;
  max-height: calc(100% - 84px) !important;
  overflow: auto;
}

.close-share-part-list-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}