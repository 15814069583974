.clone-config-modal {
  width: 85vw;
  max-width: 1000px;
  height: 80vh;
  max-height: 800px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
}

.clone-config-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
}

.clone-config-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 10px 24px !important;
  width: calc(100%) !important;
  height: calc(100%) !important;
  max-height: calc(100%) !important;
  overflow: auto;
}

.close-clone-config-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}