.copy-link-modal {
  top: 250px !important;
}
.share-link-modal-content {
  text-align: left;
  width: 500px;
}

.share-link-modal-content h4 {
  color: #000;
  font-weight: 400;
}
