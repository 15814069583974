.user-settings-form {
  margin: auto;
  margin-top: 20px;
  max-width: 800px;
  max-height: 700px;
  padding: 25px 50px;

  background: #ffffff;
  border-radius: 7px;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.25);

  .user-section-title {
    svg {
      position: relative;
      top: 2px;
    }
  }

  .password-input {
    margin-bottom: 10px;

    .password-input-label {
      margin-top: 10px;
    }
  }

  .clear-pass-button {
    cursor: pointer;

    &:hover {
      color: #ff3c3c;
      opacity: 0.75;
    }
  }

  & > * {
    margin-bottom: 20px !important;
  }
}
