.add-to-part-list-modal {
  width: 85vw;
  max-width: 500px;
  height: 60vh;
  max-height: 650px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
}

.add-to-part-list-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
}

.add-to-part-list-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 20px !important;
  width: calc(100%) !important;
  height: calc(100%) !important;
  overflow: auto;
}

.close-add-to-part-list-modal-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 999999 !important;
}

.create-part-list-nested-modal {
  width: 70vw;
  max-width: 400px;
  height: 20vh;
  max-height: 160px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  border-radius: 5px;
}

.create-part-list-nested-modal-content {
  position: absolute;
  margin: 0 !important;
  padding: 20px !important;
  width: calc(100%) !important;
  height: calc(100%) !important;
  overflow: auto;
}